import Layout from '@layouts';
import { SecurityContext } from '@lib';
import { Link, navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext } from 'react';

import { useUserStatusGuard } from './helpers';

const AccountDisabledPage: FC<PageProps> = ({}) => {
  useUserStatusGuard(true);
  const { logout } = useContext(SecurityContext);

  return (
    <Layout url="/profile/disabled/" title="Account disabled" disableIndex>
      <pre className="mt-10 whitespace-pre-wrap">
        Your account is disabled. If you think this is a mistake, please contact
        the admin.
      </pre>
      <div className="flex flex-col space-y-4 p-4">
        <Link className="" to="/contact/">
          Contact Us
        </Link>
        <a
          className=""
          href="/logout/"
          onClick={async (event) => {
            event.preventDefault();
            await logout();
            return navigate('/');
          }}
        >
          Logout
        </a>
      </div>
    </Layout>
  );
};

export default AccountDisabledPage;
